import React, { useCallback, useState } from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import forIn from 'lodash/forIn'
import { useForm } from 'react-hook-form'

import Layout from '@c/layout'
import Book from '@c/book'
import AuthorCard from '@c/author-card'
import FormInput from '@c/form-input'
import FormCheckbox from '@c/form-checkbox'
import Modal from '@c/modal'
import ShareBox from '@c/share-box'
import BookModel from '@m/Book'
import makeSlug from '@u/make-slug'

import './index.css'

const GATSBY_PACKAGE_REGISTER_FORM_POST_URL =
  process.env.GATSBY_PACKAGE_REGISTER_FORM_POST_URL
const sectors = [
  'Bilgi Teknolojileri',
  'Cam, Çimento ve Toprak',
  'Çevre',
  'Diğer',
  'Dijital Eğlence & Hobiler & Oyuncaklar',
  'E-Ticaret / Pazar Yeri',
  'Enerji',
  'Eğitim',
  'Elektronik Aletler',
  'Finans',
  'FMCG (Hızlı Tüketim Malları)',
  'Gayrimenkul',
  'Gıda',
  'İnşaat',
  'Kağıt ve Kağıt Ürünleri',
  'Kimyasallar, Petrol, Kauçuk ve Plastikler',
  'Kültür, Sanat ve Tasarım',
  'Madencilik ve Metal',
  'Medya, İletişim ve Yayıncılık',
  'Mobilya ve Ev Tekstili',
  'Moda ve Giyim',
  'Otomotiv',
  'Online Pazar',
  'Reklam ve Pazarlama',
  'Sağlık',
  'Sigorta',
  'Spor ve Fitness',
  'Tarım, Av ve Balıkçılık',
  'Turizm ve Ulaşım',
];
const peopleCountOptions = [
  '1 Kişi',
  '2 Kişi',
  '3 Kişi',
  '4 Kişi',
  '5 Kişi',
  '6 kişi ve üzeri',
]
const educationPrograms = [
  'Generative AI Temelleri (1 Gün)',
  'Generative AI İleri Seviye Eğitim (4 Hafta)'
]
const Page = ({ data, pageContext }) => {
  const { pack, pageSettings } = data
  const { author, coverTitleFirst, coverTitleSecond, title } = pack
  const model = new BookModel(pack)
  const [isOtherFieldRequired, setIsOtherFieldRequired] = useState(false)
  const [sectorTouched, setSectorTouched] = useState(false);
  const [peopleCountTouched, setPeopleCountTouched] = useState(false);
  const [educationProgramTouched, setEducationProgramTouched] = useState(false);
  const form = useForm()
  form.register('sector', { required: true })
  form.register('peopleCount', { required: true })
  form.register('educationProgram', { required: true })

  const [state, setState] = useState(() => ({
    modalContent: '',
    modalToggle: 'd-none'
  }))

  const onSubscribe = useCallback(
    (e) => {
      e.preventDefault()
      const url = GATSBY_PACKAGE_REGISTER_FORM_POST_URL
      const formData = new FormData()
      let contactListId = 'contactlist_' + pack.autopilotappContactListId

      forIn(form.getValues(), (value, key) => formData.append(key, value))
      formData.append('title', title)
      formData.append('contactListId', contactListId)
      setState({ modalToggle: 'modal', modalContent: 'Please wait...' })

      fetch(url, {
        method: 'post',
        body: formData
      })
        .then(async (response) => ({ response, body: await response.text() }))
        .then(() => {
          setState({ modalToggle: 'd-none' })
        })
        .then(() => {
          const script = document.createElement('script')
          script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-10788503097'
          script.async = true
          document.head.appendChild(script)
          window.dataLayer = window.dataLayer || [];
          function gtag() {
            window.dataLayer.push(arguments);
          }
          gtag('js', new Date());
          gtag('config', 'AW-10788503097');
          gtag('event', 'conversion', {
            'send_to': 'AW-10788503097/jjgFCOnZ-LwZELn8rZgo',
            'value': 1.0,
            'currency': 'TRY'
          });
          console.log('here');
        })
        .then(() => {
          form.reset()
          setIsOtherFieldRequired(false)
          setState({
            modalToggle: 'modal',
            modalContent:
              'You can see our report by clicking on the link sent to your e-mail address.'
          })
          if (typeof window !== 'undefined') {
            const selectedProgram = form.getValues().educationProgram;
            if (selectedProgram === 'Generative AI Temelleri (1 Gün)') {
              window.open('https://iyzi.link/AJr4Fg', '_blank');
            } else if (selectedProgram === 'Generative AI İleri Seviye Eğitim (4 Hafta)') {
              window.open('https://iyzi.link/AJr4Fw', '_blank');
            }
          }
        })
        .catch(() => {
          setState({
            modalToggle: 'modal',
            modalContent: 'An error occurred, please try again.'
          })
        })
        .finally(() => {
          setTimeout(() => {
            setState({ modalToggle: 'd-none' })
          }, 3000)
        })
    },
    [form, title]
  )

  const url = `https://zeo.org${model.getPath(pageSettings)}`
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    url
  )}`
  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
    title
  )}&url=${encodeURIComponent(url)}`
  const linkedinLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
    url
  )}`
  const whatsappLink = `whatsapp://send?text=${encodeURIComponent(url)}`
  const mailLink = `mailto:?subject=${encodeURIComponent(
    title
  )}&body=${encodeURIComponent(url)}`

  const onSectorChange = (ev) => {
    const val = ev.target.value
    setSectorTouched(true)
    if (val === 'Other') {
      setIsOtherFieldRequired(true)
      form.register('other_sector', { required: 'true' })
    } else {
      setIsOtherFieldRequired(false)
      form.unregister('other_sector')
    }
    form.setValue('sector', val)
  }

  const onPeopleCountChange = (ev) => {
    const val = ev.target.value
    setPeopleCountTouched(true)
    form.setValue('peopleCount', val)
  }

  const onEducationProgramChange = (ev) => {
    const val = ev.target.value
    setEducationProgramTouched(true)
    form.setValue('educationProgram', val)
  }

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps(pageSettings) }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 offset-lg-1 col-lg-5 col-xl-4 order-lg-1">
            <form onSubmit={onSubscribe.bind(this)}>
              <Book
                photo={get(pack, 'coverImage')}
                alt={get(pack, 'coverImage.alt') || ''}
                title={coverTitleFirst}
                titleSecond={coverTitleSecond}
              />
              <div className="py-24 bg-soft-blue flex-column align-items-center justify-content-center">
                <div className="col-12 my-24">
                  <FormInput
                    name="name"
                    placeholder="İsim"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 my-24">
                  <FormInput
                    name="lastname"
                    placeholder="Soyisim"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <FormInput
                    name="companyName"
                    placeholder="Şirket Adı"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <div className="form-select form--required">
                    <select
                      name="educationProgram"
                      style={{ color: educationProgramTouched ? 'black' : '#757575' }}
                      required
                      onChange={onEducationProgramChange}
                      onBlur={onEducationProgramChange}
                      defaultValue={''}
                    >
                      <option disabled value="">
                        Eğitim Programı
                      </option>
                      {educationPrograms.map((program) => (
                        <option
                          style={{ color: 'black' }}
                          key={program}
                          value={program}
                        >
                          {program}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-12 mb-24">
                  <FormInput
                    name="jobTitle"
                    placeholder="Pozisyon"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 mb-24">
                  <div className="form-select form--required">
                    <select
                      name="sector"
                      style={{ color: sectorTouched ? 'black' : '#757575' }}
                      required
                      onChange={onSectorChange}
                      onBlur={onSectorChange}
                      defaultValue={''}
                    >
                      <option disabled value="">
                        Sektör
                      </option>
                      {sectors.map((type) => (
                        <option
                          style={{ color: 'black' }}
                          key={type}
                          value={type}
                        >
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                {isOtherFieldRequired && (
                  <div className="col-12 mb-24">
                    <FormInput
                      name="other_sector"
                      placeholder="Sektör"
                      required
                      onChange={(ev) =>
                        form.setValue('other_sector', ev.target.value)
                      }
                      onBlur={(ev) =>
                        form.setValue('other_sector', ev.target.value)
                      }
                    />
                  </div>
                )}
                {pack.formHasPeopleCountField && (
                  <div className="col-12 mb-24">
                    <div className="form-select form--required">
                      <select
                        name="peopleCount"
                        style={{ color: peopleCountTouched ? 'black' : '#757575' }}
                        required
                        onChange={onPeopleCountChange}
                        onBlur={setPeopleCountTouched}
                        defaultValue={''}
                      >
                        <option disabled value="">
                          {' '}
                        Kişi Sayısı{' '}
                        </option>
                        {peopleCountOptions.map((type) => (
                          <option
                            style={{ color: 'black' }}
                            key={type}
                            value={type}
                          >
                            {type}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                )}
                <div className="col-12 mb-24">
                  <FormInput
                    type="email"
                    name="email"
                    placeholder="E-posta"
                    required={true}
                    ref={form.register()}
                  />
                </div>
                <div className="col-12 fs-small mb-24">
                  <FormCheckbox
                    name="checkbox"
                    ref={form.register()}
                    required={true}
                  >
                    Bilgilendirme e-postalarını almayı kabul ediyorum.
                  </FormCheckbox>
                </div>
                <div className="col-12 d-flex justify-content-end mb-24">
                  <button type="submit" className="btn btn--dark">
                    {pack.formActionText || 'Kayıt Ol'}
                  </button>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-lg-6 col-xl-7 mt-40 mt-lg-0">
            <h1>{pack.title2}</h1>

            {/* start-meta */}
            <div className="d-flex align-items-center flex-wrap mt-40">
              {author && (
                <AuthorCard
                  photo={get(author, 'profileImage')}
                  alt={get(author, 'profileImage.alt') || ''}
                  name={`${author.firstName} ${author.lastName}`}
                  nickname={author.nickname}
                  categoryBase={makeSlug.generic(
                    pageContext.locale,
                    pageSettings.resourcesSlug,
                    pageSettings.resourcesBookSlug,
                    pageSettings?.categorySlug
                  )}
                  categories={pack.categories}
                  locale={pageContext.locale}
                />
              )}
              <span className="ml-auto">
                <ShareBox
                  facebookLink={facebookLink}
                  twitterLink={twitterLink}
                  mailLink={mailLink}
                  whatsAppLink={whatsappLink}
                  linkedinLink={linkedinLink}
                />
                 </span>
            </div>
            {/* end-meta */}

            <div
              className="mt-40"
              dangerouslySetInnerHTML={{ __html: pack.shortDescription }}
            ></div>
            <div
              className="mt-40 book-content"
              dangerouslySetInnerHTML={{ __html: pack.description }}
            ></div>
          </div>
        </div>
        <Modal
          modalContent={state.modalContent}
          modalToggle={state.modalToggle}
        />
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ($packageId: String, $locale: String) {
    pack: datoCmsPackage(id: { eq: $packageId }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      slug
      title
      title2
      formHasPeopleCountField
      formActionText
      coverTitleFirst
      coverTitleSecond
      shortDescription
      autopilotappContactListId
      description
      categories {
        title
        slug
      }
      file {
        url
      }
      coverImage {
        alt
        asset {
          localImage {
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }

      author {
        firstName
        lastName
        nickname
        profileImage {
          alt
          asset {
            localImage {
              extension
              publicURL
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 30)
              }
            }
          }
        }
      }
    }

    pageSettings: datoCmsPageSetting(locale: { eq: $locale }) {
      _allResourcesSlugLocales {
        locale
        value
      }
      _allResourcesBookSlugLocales {
        locale
        value
      }
      resourcesTitle
      resourcesSlug
      resourcesBookTitle
      resourcesBookSlug
      categorySlug
    }

    # Translations
    translations: datoCmsTranslation(locale: { eq: $locale }) {
      ...TranslationsFragment
    }
  }
`
